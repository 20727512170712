import type {
  COMMENT_SORT_TYPE,
  COMMENT_TYPE,
  EASY_LOGIN_USAGE_CODE,
  LOGIN_TYPE,
  TERM_CATEGORY,
  POLICY_CASE_TYPE,
  POLICY_CASE_BABY_CHILD_COUNT,
  VERIFICATION_SERVICE_TYPE,
} from '@/api';
import type { PROXY_TARGET } from '@/pages/api/proxy/[...all]';
import type {
  EasyCertificationParams,
  FAMILY_PROFILE_UPDATE_PAGE_TYPE,
} from '@/types';
import type { ERROR_CODE } from '@common/utils';

import { createRoute } from '@/utils';

export const ROUTES = {
  TEST: createRoute('/test'),

  HOME: createRoute('/', {
    hasBottomNav: true,
  }),

  LOGIN: createRoute<{
    searchParams: {
      logout?: boolean;
      redirect?: string;
      disableOutlink?: boolean;
      memberCode?: string;
    };
  }>('/login'),

  PEEC_EVENT: createRoute('/event/peec'),

  PEEC_APPLY: createRoute('/event/peec/apply'),

  AUTH: createRoute<{
    searchParams: {
      'login-type'?: LOGIN_TYPE;
      'social-login-token'?: string;
      code?: string;
      state?: string;
    };
  }>('/login/auth'),

  COMMUNITY: createRoute('/community', {
    hasBottomNav: true,
  }),
  COMMUNITY_CATEGORY: createRoute<{
    dynamicPath: { category: string; idIdx?: string };
  }>(
    ({ category, idIdx }) =>
      `/community/${category}${idIdx ? `/${idIdx}` : ''}`,
    {
      hasBottomNav: true,
    },
  ),
  WELLO_NEWS_DETAIL: createRoute<{
    dynamicPath: { idIdx: string };
  }>(({ idIdx }) => `/community/welloNews/${idIdx}`),
  POLICY_TALK_DETAIL: createRoute<{
    dynamicPath: { idIdx: string };
  }>(({ idIdx }) => `/community/policyTalk/${idIdx}`),
  FREE_TALK_DETAIL: createRoute<{
    dynamicPath: { idIdx: string };
  }>(({ idIdx }) => `/community/freeTalk/${idIdx}`),
  HOMETOWN_NEWS_DETAIL: createRoute<{
    dynamicPath: { idIdx: string };
  }>(({ idIdx }) => `/community/hometownNews/${idIdx}`),
  POLICY_INFO_DETAIL: createRoute<{
    dynamicPath: { idIdx: string };
  }>(({ idIdx }) => `/community/policyInfo/${idIdx}`),

  POLICY_TALK_POST_EDIT: createRoute<{
    dynamicPath: {
      idx: string;
    };
  }>(({ idx }) => `/community/policyTalk/posting/${idx}`),
  POLICY_TALK_POST_NEW: createRoute<{
    searchParams: {
      code?: string;
    };
  }>('/community/policyTalk/posting/new'),
  FREE_TALK_POST_EDIT: createRoute<{
    dynamicPath: {
      idx: string;
    };
  }>(({ idx }) => `/community/freeTalk/posting/${idx}`),
  FREE_TALK_POST_NEW: createRoute('/community/freeTalk/posting/new'),

  PROFILE: createRoute('/my-info', {
    hasBottomNav: true,
  }),

  PROFILE_SCRAP: createRoute('/my-info/my-scrap', {
    hasBottomNav: true,
  }),

  PROFILE_COMMUNITY_POSTED: createRoute('/my-info/my-community/posted', {
    hasBottomNav: true,
  }),
  PROFILE_COMMUNITY_COMMENTED: createRoute('/my-info/my-community/commented', {
    hasBottomNav: true,
  }),
  PROFILE_COMMUNITY_LIKED: createRoute('/my-info/my-community/liked', {
    hasBottomNav: true,
  }),

  PROFILE_APPLY: createRoute('/my-info/my-apply', {
    hasBottomNav: true,
  }),
  PROFILE_REWARD: createRoute('/my-info/reward'),

  RECOMMEND_POLICY_SITUATION_MAIN: createRoute<{
    dynamicPath: { caseType: POLICY_CASE_TYPE | 'ALL' };
  }>(({ caseType }) => `/recommend-policy/situation/main/${caseType}`),

  RECOMMEND_POLICY_SITUATION_BABY_FORM: createRoute(
    '/recommend-policy/situation/baby',
  ),

  RECOMMEND_POLICY_SITUATION_BABY_REPORT: createRoute<{
    dynamicPath: {
      subRegionCode: string;
      childCount: POLICY_CASE_BABY_CHILD_COUNT;
    };
  }>(
    ({ subRegionCode, childCount }) =>
      `/recommend-policy/situation/baby/report/${subRegionCode}/${childCount}`,
  ),

  RECOMMEND_POLICY_SITUATION_EMERGENCY_FORM: createRoute(
    '/recommend-policy/situation/emergency',
  ),
  RECOMMEND_POLICY_SITUATION_EMERGENCY_HOSPITAL_LIST: createRoute(
    '/recommend-policy/situation/emergency/list',
  ),
  RECOMMEND_POLICY_SITUATION_EMERGENCY_MAP: createRoute(
    '/recommend-policy/situation/emergency/map',
  ),
  RECOMMEND_POLICY_SITUATION_EMERGENCY_DETAIL: createRoute<{
    dynamicPath: { id: number };
    searchParams: {
      symptomCode?: string;
      isEmergency?: boolean;
    };
  }>(({ id }) => `/recommend-policy/situation/emergency/${id}`),
  MEDICAL_POLICY_LIST: createRoute('/recommend-policy/medical'),

  PUSH: createRoute('/push'),

  PROFILE_EDIT: createRoute('/my-info/edit'),

  FAMILY_PROFILE: createRoute('/my-info/settings/family'),

  FAMILY_PROFILE_UPDATE: createRoute<{
    dynamicPath: {
      updateType: FAMILY_PROFILE_UPDATE_PAGE_TYPE;
    };
    searchParams: {
      familyId?: number;
    };
  }>(({ updateType }) => `/my-info/settings/family/${updateType}`),

  FAMILY_FILTER_UPDATE: createRoute<{
    dynamicPath: {
      updateType: FAMILY_PROFILE_UPDATE_PAGE_TYPE;
    };
    searchParams: {
      familyId?: number;
    };
  }>(({ updateType }) => `/my-info/settings/family/${updateType}/filter`),

  SEARCH: createRoute<{
    searchParams: {
      /** @description 검색어 */
      q: string;
    };
  }>('/search'),

  RECOMMEND_POLICY_LIST: createRoute<{
    searchParams: { familyId?: number };
  }>('/recommend-policy', {
    hasBottomNav: true,
  }),

  POLICY_DETAIL: createRoute<{
    dynamicPath: { idIdx: string };
  }>(({ idIdx }) => `/policy/${idIdx}`),

  COMMENT_LIST: createRoute<{
    dynamicPath: {
      commentType: COMMENT_TYPE;
      contentId: number;
      commentId: number;
    };
    searchParams: {
      sortType?: COMMENT_SORT_TYPE;
      parentCommentId?: number;
      policyTalkContentIdx?: string;
    };
  }>(
    ({ commentType, contentId, commentId }) =>
      `/comment/${commentType}/${contentId}/${commentId}`,
  ),
  COMMENT_EDIT: createRoute<{
    dynamicPath: { commentId: number };
  }>(({ commentId }) => `/comment/edit/${commentId}`),

  POLICY_TALK_COMMENT_NEW: createRoute<{
    dynamicPath: { contentIdx: string };
  }>(
    ({ contentIdx }) =>
      `/community/policyTalk/posting/${contentIdx}/comment/new`,
  ),
  POLICY_TALK_COMMENT_EDIT: createRoute<{
    dynamicPath: { contentIdx: string; commentId: number };
  }>(
    ({ contentIdx, commentId }) =>
      `/community/policyTalk/posting/${contentIdx}/comment/${commentId}`,
  ),

  HOMETOWN_NEWS_SEARCH: createRoute('/community/hometownNews/search'),

  /**@name 설정 */
  SETTINGS: createRoute('/my-info/settings'),

  /**@name 알림설정 */
  NOTIFICATION_SETTINGS: createRoute('/my-info/settings/notifications'),

  /**@name 계정연동 */
  ACCOUNT_LINKING: createRoute('/my-info/settings/account-linking'),

  /**@name 공지사항*/
  NOTICE: createRoute('/my-info/settings/notice'),

  /**@name 공지사항_상세 */
  NOTICE_DETAIL: createRoute<{
    dynamicPath: { id: number };
  }>(({ id }) => `/my-info/settings/notice/${id}`),

  /**@name 자주_묻는_질문 */
  FAQ: createRoute('/my-info/settings/faq'),

  /**@name 고객_문의 */
  INQUIRY_LIST: createRoute('/my-info/settings/inquiry'),

  /**@name 문의_하기 */
  INQUIRY_EDIT: createRoute('/my-info/settings/inquiry/register'),

  /**@name 문의_상세 */
  INQUIRY_DETAIL: createRoute<{
    dynamicPath: { id: number };
  }>(({ id }) => `/my-info/settings/inquiry/${id}`),

  /**@name 회원탈퇴 */
  RESIGN_MEMBER: createRoute('/my-info/settings/resign-member'),

  PICK_POLICY_LIST: createRoute<{ dynamicPath: { id: number } }>(
    ({ id }) => `/pick/${id}`,
  ),

  SITUATION_POLICY_LIST: createRoute<{
    dynamicPath: { id: number };
  }>(({ id }) => `/situation/${id}`),

  /** @name 맞춤_정책_필터_수정 */
  MY_POLICY_FILTER: createRoute('/my-info/edit/filter'),

  /**@name 장학재단 관련 */
  POLICY_KOSAF_APPLY: createRoute('/policy/apply/1'),
  POLICY_REPORT: createRoute('/policy/report'),

  POLICY_REPORT_DETAIL: createRoute<{
    searchParams: {
      /** @description 장학재단 신고 내역 상세 */
      report: string;
      reportYear: string;
      reportDate: string;
    };
  }>('/policy/report/detail'),

  /**@name 제주도 관련 */
  JEJU_APPLY: createRoute('/jeju/apply'),
  JEJU_REPORT: createRoute('/jeju/report'),
  JEJU_REPORT_DETAIL: createRoute<{
    dynamicPath: { id: number };
  }>(({ id }) => `/jeju/report/${id}`),

  POLICY_CONSENT: createRoute('/policy/consent'),
  POLICY_NOTICE: createRoute('/policy/notice'),
  POLICY_NOTICE_DETAIL: createRoute<{
    dynamicPath: { year: number };
  }>(({ year }) => `/policy/notice/${year}`),

  CROSS_DOMAIN_AUTH: createRoute<{
    searchParams: {
      accessToken: string;
      refreshToken: string;
    };
  }>('/login/auth/cross-domain'),

  CROSS_DOMAIN_CALLBACK: createRoute<{
    searchParams: {
      accessToken: string;
      refreshToken: string;
      redirect?: string;
      redirectSearchParams?: string;
    };
  }>('/login/auth/cross-domain/callback'),

  JOIN_COMMON: createRoute<{
    searchParams: {
      step?: number;
    };
  }>('/join/common'),

  JOIN_COMPLETE: createRoute('/join/complete'),

  JOIN_USER_INFO: createRoute<{
    searchParams: {
      verificationId?: number;
    };
  }>('/join/user-info'),

  POLICY_FILTER: createRoute<{
    dynamicPath: { situation: string };
  }>(({ situation }) => `/filter/policy/${situation}`),

  INTEREST_POLICY_FILTER: createRoute<{
    dynamicPath: { situation: string };
  }>(({ situation }) => `/filter/policy/interest/${situation}`),

  KB_PAY_FILTER: createRoute('/filter/kb-pay'),

  TERMS: createRoute<{
    dynamicPath: { terms: TERM_CATEGORY };
  }>(({ terms }) => `/terms/${terms}`),

  TERMS_PRIVATE: createRoute<{
    dynamicPath: { terms: TERM_CATEGORY };
  }>(({ terms }) => `/my-info/settings/terms/${terms}`),

  EASY_CERTIFICATION: createRoute<{
    searchParams: EasyCertificationParams;
  }>('/easy-certification'),
  EASY_CERTIFICATION_CALLBACK: createRoute<{
    searchParams: EasyCertificationParams & {
      encryptMOKKeyToken?: string;
      serviceType?: string;
      txId?: string;
      usageCode?: EASY_LOGIN_USAGE_CODE;
    };
  }>('/easy-certification/callback'),

  ERROR: createRoute<{
    dynamicPath: { code: ERROR_CODE };
  }>(({ code }) => `/error/${code}`),

  NICE_CERTIFICATION: createRoute('/easy-certification/nice'),
  NICE_RESULT: createRoute<{
    dynamicPath: {
      result: 'pending' | 'success' | 'fail';
    };
  }>(({ result }) => `/easy-certification/nice/${result}`),
  /** default request categoryId: 0 */
  VOUCHER_CATEGORY: createRoute<{
    dynamicPath: {
      categoryId: number;
    };
  }>(({ categoryId }) => `/voucher/${categoryId}`, {
    hasBottomNav: true,
  }),
  VOUCHER_DETAIL: createRoute<{
    dynamicPath: {
      voucherId: string;
    };
  }>(({ voucherId }) => `/voucher/detail/${voucherId}`),
  /** default request categoryId: 0 */
  VOUCHER_DESCRIPTION_SERVICE_CATEGORY: createRoute<{
    dynamicPath: {
      categoryId: number;
    };
  }>(({ categoryId }) => `/voucher/description/service/${categoryId}`),
  VOUCHER_DESCRIPTION_CARD: createRoute('/voucher/description/card'),
  VOUCHER_LIST: createRoute<{
    dynamicPath: { category: number };
  }>(({ category }) => `/voucher/list/${category}`),
  VOUCHER_MAP: createRoute<{
    searchParams: { category: number };
  }>('/voucher/map', {
    toastDirection: 'top',
  }),
  /** default request categoryId: 0 */
  VOUCHER_ONLINE_CATEGORY: createRoute<{
    dynamicPath: { category: number };
  }>(({ category }) => `/voucher/online/${category}`),
  CHILD_CARE_SERVICE_VOUCHER: createRoute('/voucher/child-care-service'),
  NATIONAL_HAPPINESS_CARD_VOUCHER_ONLINE: createRoute(
    '/voucher/national-happiness-card/online',
  ),
  NATIONAL_HAPPINESS_CARD_VOUCHER_OFFLINE: createRoute(
    '/voucher/national-happiness-card/offline',
  ),
  CONTENT_COMPLIANCE_INFORMATION: createRoute(
    '/content-compliance-information',
  ),
  WELLPER_INTRODUCTION: createRoute('/wellper/introduction'),
  WELLPER_APPLY: createRoute('/wellper/apply'),
  WELLPER_VERIFY_PHONE: createRoute('/wellper/verify-phone'),
  WELLPER_DOCUMENTS_UPLOAD: createRoute('/wellper/documents-upload'),
  WELLPER_DOCUMENTS_UPLOAD_INPUT: createRoute(
    '/wellper/documents-upload-input',
  ),
  WELLPER_APPLICATION_COMPLETED: createRoute('/wellper/application-completed'),
  POINTS: createRoute('/points'),
  POINTS_OVERVIEW: createRoute('/points/overview'),
  POINTS_FORM: createRoute('/points/form'),
  POINTS_WITHDRAWAL_SUCCESS: createRoute('/points/withdrawal-success'),
  /** 친구 초대 */
  POINTS_INVITE: createRoute('/points/invite'),
  /** 매일 출석 체크 */
  ATTENDANCE_DAILY_CHECK: createRoute('/attendance/daily-check'),

  CARD: createRoute('/card'),
  REWARD: createRoute('/card/reward'),
  CARD_BENEFIT: createRoute('/card/benefit'),
  CARD_INVITE: createRoute('/card/invite'),
  CARD_UPLOAD: createRoute<{
    searchParams: { cardName: string; memberCardId: number };
  }>('/card/upload'),

  K_PASS_APPLY: createRoute('/card/k-pass/apply'),
  HAPPINESS_CARD_APPLY: createRoute('/card/happiness/apply'),
  CEO_CARD_APPLY: createRoute('/card/ceo/apply'),

  K_PASS_BENEFIT: createRoute('/card/k-pass/benefit'),
  HAPPINESS_CARD_BENEFIT: createRoute('/card/happiness/benefit'),
} as const;

export const API_ROUTES = {
  NAVER_AUTH: createRoute('/api/auth/naver'),
  GOOGLE_AUTH: createRoute('/api/auth/google'),
  APPLE_AUTH: createRoute('/api/auth/apple'),
  APPLE_TOKEN: createRoute('/api/auth/apple/token'),
  REDIRECT: createRoute('/api/redirect'),
  MOBILE_OK_REQUEST: createRoute<{
    searchParams: EasyCertificationParams & {
      usageCode: EASY_LOGIN_USAGE_CODE;
    };
  }>('/api/mobile-ok'),
  MOBILE_OK_CALLBACK: createRoute<{
    searchParams: EasyCertificationParams & {
      usageCode: EASY_LOGIN_USAGE_CODE;
      txId: string;
      serviceType: VERIFICATION_SERVICE_TYPE;
    };
  }>('/api/mobile-ok/callback'),
  IOK_REQUEST: createRoute<{
    searchParams: EasyCertificationParams & {
      usageCode: EASY_LOGIN_USAGE_CODE;
      authProviderCode: string;
    };
  }>('/api/iok'),
  IOK_CALLBACK: createRoute<{
    searchParams: EasyCertificationParams & {
      usageCode: EASY_LOGIN_USAGE_CODE;
      txId: string;
      serviceType: VERIFICATION_SERVICE_TYPE;
    };
  }>('/api/iok/callback'),
  JEJU_APP_API: createRoute('/api/jeju-app'),
  DOWNLOAD_APP: createRoute('/api/download-app'),
  PROXY: createRoute<{
    dynamicPath: { endpoint: keyof typeof PROXY_TARGET };
  }>(({ endpoint }) => `/api/proxy/${endpoint}`),
} as const;

export const MODULE_ROUTES = {
  KB_HOME: createRoute('/kb-home', {
    hasBottomNav: true,
  }),
};
