export enum STORAGE_KEY {
  SEARCH_HISTORY = 'searchHistory',
  EASY_CERTIFICATION = 'easyCertification',
  KOSAF_PROCESS = 'kosafProcess',
  JEJU_YOUTHDREAM = 'jejuYouthdream',
  NICE_FORM_VALUES = 'niceFormValues',
  LOGOUT_TOAST = 'logoutToast',
  POLICY_FILTER_LANDING = 'policyFilterLanding',
  POLICY_FILTER_LANDING_FROM = 'policyFilterFrom',
  POLICY_FILTER = 'policyFilter',
  HOMETOWN_NEWS_DETAIL_BOTTOM_DRAWER_PATH = 'hometownNewsDetailBottomDrawerPath',
  IS_NOT_SEE_AGAIN_VOUCHER_RECOMMEND = 'isNotSeeAgainVoucherRecommend',
  VOUCHER_MAIN_SCROLL = 'voucherMainScroll',
  VOUCHER_DETAIL_SCROLL = 'voucherDetailScroll',
  MY_INFO_PROFILE_SCRAP_ACTIVE_TAB = 'myInfoProfileScrapActiveTab',
  /** 회원 가입시 어떤 사용자가 추천했는지를 구분할 수 있는 코드 */
  MEMBER_CODE = 'memberCode',
  /** 웰퍼 신청 프로세스 중에 임시로 저장해 놓는 정책 분야 태그들 */
  WELLPER_APPLY_POLICY_TEMP_VALUES = 'wellperApplyPolicyTempValues',

  /** history 관련 정보 */
  HISTORY = 'history',
  HISTORY_EXPIRE = 'historyExpire',
  HISTORY_INDEX = 'historyIndex',
  REPLACE_MARK = 'replaceMark',
}

export namespace STORAGE_TYPE {
  export type HISTORY = [number, string][];
}
