import type { UNAUTH_API } from '@/api';

export enum SEARCH_PARAMS {
  ORIGINAL_PATH = 'original-path',
  POST_REDIRECT = 'post-redirect',
  APP_LOGIN_TYPE = 'app-login',
  ACCESS_TOKEN = 'accessToken',
  REFRESH_TOKEN = 'refreshToken',
  /**
   * @description redirect pathname
   */
  REDIRECT = 'redirect',
  /**
   * @description redirect searchParams
   */
  REDIRECT_SEARCH_PARAMS = 'redirectSearchParams',
  VERIFICATION = 'verification',
  ENTER_TYPE = 'enter-type',
  SHARE_BY = 'share-by',
  MEMBER_CODE = 'memberCode',

  //! 응급생활 관련 파라미터
  SYMPTOM_CODE = 'symptomCode',
  EMERGENCY_ROOM_TIME = 'emergencyRoomTime',
  IS_REAL_DISTANCE = 'isRealDistance',
  RADIUS = 'radius',
  EMERGENCY_ROOM_PARAMS = 'emergencyRoomParams',
  SELECTED_REGION = 'selectedRegion',
  PARENT_COMMENT_ID = 'parentCommentId',
  SORT_TYPE = 'sortType',
  HOME_BOTTOM_DRAWER = 'home-bottom-drawer',
}

export namespace SEARCH_PARAMS_TYPE {
  export interface EMERGENCY_ROOM_PARAMS
    extends Omit<
      Parameters<typeof UNAUTH_API.getEmergencyRoomList>[number],
      'page' | 'size'
    > {
    isRealDistance?: boolean;
  }

  export interface SELECTED_REGION {
    mainRegionCode?: string;
    subRegionCode?: string;
  }
}
