export enum COOKIES_KEYS {
  ACCESS_TOKEN = 'accessToken',
  REFRESH_TOKEN = 'refreshToken',

  ORIGIN_HOST = 'hostOrigin',
  LOGIN_PARAMS = 'loginParams',

  LOGOUT_OPTIONS = 'logoutOptions',
  K_PASS_JOIN = 'kPassJoin',
}

export const COOKIE_OPTIONS = {
  domain:
    process.env.NODE_ENV === 'development'
      ? 'localhost'
      : `.${(process.env.NEXT_PUBLIC_DOMAIN ?? '').replace('https://', '')}`,
  path: '/',
  secure: true,
};
