import { IS_PRODUCT } from './env';

export * from './routes';
export * from './storage-key';
export * from './tabs';
export * from './search-params';
export * from './seo';
export * from './cookies';
export * from './env';
export * from './selector';

export const [GTM_ID, GA_ID] = IS_PRODUCT
  ? ['GTM-WF9XK27', 'G-WKDK663EEQ']
  : ['GTM-NJCCF8P', 'G-7FCSFN8PD7'];
