//TODO: 추후 환경변수는 모두 해당 파일에서 관리

export const PRODUCT_DOMAIN = 'https://welfarehello.com';

const ENV = {
  NODE_ENV: process.env.NODE_ENV,
  NEXT_PUBLIC_DOMAIN: process.env.NEXT_PUBLIC_DOMAIN as string,
  NEXT_PUBLIC_API_DOMAIN: process.env.NEXT_PUBLIC_API_DOMAIN as string,
  NEXT_PUBLIC_GOOGLE_CLIENT_ID: process.env
    .NEXT_PUBLIC_GOOGLE_CLIENT_ID as string,
  NEXT_PUBLIC_KAKAO_CLIENT_ID: process.env
    .NEXT_PUBLIC_KAKAO_CLIENT_ID as string,
  NEXT_PUBLIC_NAVER_CLIENT_ID: process.env
    .NEXT_PUBLIC_NAVER_CLIENT_ID as string,
  NEXT_PUBLIC_APPLE_CLIENT_ID: process.env
    .NEXT_PUBLIC_APPLE_CLIENT_ID as string,
  NEXT_PUBLIC_JEJU_API_DOMAIN: process.env
    .NEXT_PUBLIC_JEJU_API_DOMAIN as string,
  NEXT_PUBLIC_JEJU_YOUTHDREAM_API_KEY: process.env
    .NEXT_PUBLIC_JEJU_YOUTHDREAM_API_KEY as string,
  NEXT_PUBLIC_DPG_API_DOMAIN: process.env.NEXT_PUBLIC_DPG_API_DOMAIN as string,
  NEXT_PUBLIC_NAVER_CLOUD_CLIENT_ID: process.env
    .NEXT_PUBLIC_NAVER_CLOUD_CLIENT_ID as string,
  NEXT_PUBLIC_MOBILE_OK_URL: process.env.NEXT_PUBLIC_MOBILE_OK_URL as string,
  NEXT_PUBLIC_EZ_IOK_URL: process.env.NEXT_PUBLIC_EZ_IOK_URL as string,
};

for (const key in ENV) {
  if (!ENV[key as keyof typeof ENV]) {
    throw new Error(`환경변수 ${key}이 없습니다.`);
  }
}

const IS_PRODUCT = ENV.NEXT_PUBLIC_DOMAIN === PRODUCT_DOMAIN;
const IS_DEV = ENV.NODE_ENV === 'development';

let BASE_URL = ENV.NEXT_PUBLIC_DOMAIN;

if (!IS_DEV) {
  BASE_URL = BASE_URL.replace('https://', 'https://www.');
}

export { ENV, IS_DEV, IS_PRODUCT, BASE_URL };
