interface RouteOptions {
  hasBottomNav?: boolean;
  toastDirection?: 'top' | 'bottom';
}

type DynamicParams = Record<string, string | number | null | boolean>;

let routeId = 0;

export const createRoute = <
  TDynamicParams extends {
    dynamicPath?: DynamicParams;
    searchParams?: DynamicParams;
  } = {
    dynamicPath: undefined;
    searchParams: undefined;
  },
>(
  pathname: TDynamicParams['dynamicPath'] extends DynamicParams
    ? (dynamicPath: TDynamicParams['dynamicPath']) => string
    : string,
  options?: RouteOptions,
) => {
  routeId += 1;

  return Object.freeze({
    id: routeId,
    pathname,
    withSearchParams: (dynamicParams: TDynamicParams) => {
      const params = dynamicParams.searchParams ?? {};
      const searchParams = new URLSearchParams();

      for (const key in params) {
        const value = params[key];

        if (value === undefined) continue;

        searchParams.append(key, String(value));
      }

      const search = searchParams.toString();

      const _pathname =
        typeof pathname === 'function'
          ? pathname(dynamicParams.dynamicPath ?? {})
          : pathname;

      return `${_pathname}${search ? `?${search}` : ''}`;
    },
    hasBottomNav: options?.hasBottomNav ?? false,
    toastDirection: options?.toastDirection ?? 'bottom',
  } as const);
};
