import type { Metadata, Viewport } from 'next';

import { BASE_URL } from './env';

export const DEFAULT_META = {
  generator: 'Next.js',
  applicationName: '웰로',
  metadataBase: new URL(BASE_URL),
  appLinks: {
    android: {
      package: 'com.wello.welfarehello',
      app_name: '웰로 - 맞춤정책 추천·신청 서비스',
      url: 'https://play.google.com/store/apps/details?id=com.wello.welfarehello',
    },
    ios: {
      app_store_id: 6444814937,
      url: 'https://apps.apple.com/kr/app/%EC%9B%B0%EB%A1%9C-wello-%EB%A7%9E%EC%B6%A4-%EC%A0%95%EC%B1%85-%EC%B6%94%EC%B2%9C-%EC%8B%A0%EC%B2%AD-%EC%84%9C%EB%B9%84%EC%8A%A4/id6444814937',
      app_name: '웰로 Wello - 맞춤 정착 추천 신청 서비스',
    },
  },
  manifest: '/manifest.json',
  keywords: ['웰로', '정책', '취업', '육아', '정부', '보조금', '혜택'],
  title: '웰로 - 5,200만 국민을 위한 개인 맞춤형 정책 추천 서비스',
  authors: [
    {
      name: '주식회사 웰로',
      url: 'https://www.wello.info',
    },
  ],
  publisher: '주식회사 웰로',
  description:
    '내게 딱 맞는 정책을 매일 추천해드려요. ㅡ 청년부터 노년까지, 취업&창업·육아·대출 등 다양한 분야의 보조금·지원금 혜택을 찾아보세요!',
  openGraph: {
    images: 'https://cdn.welfarehello.com/common/ogimg.png',
    title: '웰로 - 5,200만 국민을 위한 개인 맞춤형 정책 추천 서비스',
    siteName: '웰로',
    type: 'website',
  },
} satisfies Metadata;

export const DEFAULT_VIEWPORT = {
  width: 'device-width',
  initialScale: 1,
  maximumScale: 1,
  minimumScale: 1,
  userScalable: false,
  themeColor: '#ffffff',
} satisfies Viewport;
