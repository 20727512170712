export enum MENU_TITLE {
  TARGET = 'TARGET',
  SUPPORT = 'SUPPORT',
  INFO = 'INFO',
  APPLY = 'APPLY',
}

export const COMMUNITY_HEADER_ITEMS = {
  welloNews: {
    key: 'welloNews',
    title: '웰로소식',
    pathname: 'welloNews',
  },
  policyInfo: {
    key: 'policyInfo',
    title: '정책소식',
    pathname: 'policyInfo',
  },
  hometownNews: {
    key: 'hometownNews',
    title: '동네소식',
    pathname: 'hometownNews',
  },
  policyTalk: {
    key: 'policyTalk',
    title: '정책수다',
    pathname: 'policyTalk',
  },
  freeTalk: {
    key: 'freeTalk',
    title: '자유수다',
    pathname: 'freeTalk',
  },
} as const;

export type CommunityKeys = keyof typeof COMMUNITY_HEADER_ITEMS;
