export enum SELECTOR {
  MAIN_WRAPPER = 'main-wrapper',
  MAIN = 'main',
  PAGE_NAV_WRAPPER = 'page-nav-wrapper',
  VOUCHER_MAP_NAVIGATION = 'voucher-map-navigation',
  VOUCHER_CONTENTS_CONTAINER = 'voucher-contents-container',
  COMMENT_LIST_TOP = 'comment-list-top',
  COMMENT_PREFIX = 'comment-id-',
  GLOBAL_NAVIGATION = 'global-navigation',
}
